<template lang="pug">
include ../../../configs/template
b-card(header-tag="header")
  div.seafarerInfoList
    div.w-100
      h2 {{$t('nostrificationAgency')}}
    div.w-100
      TableV2(
        :headers="headers"
        :items="items.results"
        :pageCount="items.count"
        :currentPage="items.current"
        :querySearch="getNostrificationMesPricePrises"
        :isLoading="isLoading"
        isHiddenTableSearch
        showExpand
        componentEdit="NostrificationMesPriceForm"
        :iconExpandedOpen="iconExpandedOpen").w-100.pa-0
        NostrificationMesPriceAdd(slot="AddBlock" actionType="add")
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  components: {
    NostrificationMesPriceAdd: () => import('./PriceForm.vue')
  },
  props: {
    sailorDocument: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      headers: [
        { value: 'date_start', text: this.$t('dateEffective'), sortable: false },
        { value: 'date_end', text: this.$t('dateTermination'), sortable: false },
        { value: 'price', text: this.$t('firstForm'), sortable: false },
        { value: 'unit', text: this.$t('secondForm'), sortable: false },
        { value: 'data-table-expand', sortable: false }
      ],
      iconExpandedOpen: {
        name: 'mdi-pen',
        color: 'blue',
        tooltip: 'tooltip.edit'
      }
    }
  },
  computed: {
    ...mapGetters(['getDirectoryObject']),
    ...mapState({
      labelName: state => state.main.lang === 'en' ? 'name_eng' : 'name_ukr',
      items: state => state.backoffice.nostrificationMesPricePrices,
      isLoading: state => state.backoffice.isLoading
    })
  },
  methods: {
    ...mapActions(['getNostrificationMesPricePrises'])
  }
}
</script>
